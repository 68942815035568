.menu ul li a {
    display: inline-block;
    margin: 10px;
    padding: 8px;
    font-weight: 600;
    text-transform: capitalize;

}



.menu ul li .active {
    background: #3B82F6;
    color: white;
    border-radius: 5px;
}