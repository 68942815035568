@tailwind base;
@tailwind components;
@tailwind utilities;

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
input,
table,
button {
    margin: 0;
    padding: 0;
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Roboto', sans-serif;

}

.Toastify__toast-body {
    font-family: 'Roboto', sans-serif;

}






.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 30%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
}


.react-responsive-modal-modal {
    max-width: 800px;
    display: inline-block;
    text-align: left;
    border-radius: 15px;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
}


.customModal {
    max-width: 40%;
    margin: auto;
    width: 100%;
}

.customModal2 {
    max-width: 30%;
    margin: auto;
    width: 100%;
}

.home-project .slick-slide {
    margin: 0px 20px;
    padding-bottom: 50px;

}

.loader {
    width: 30px;
    height: 30px;
    border: 5px solid white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 500px) {

    .home-project .slick-slide {
        margin: 0;
    }
}

.oikko-about-title {
    font-family: 'Hind Siliguri', sans-serif;
}