.header-slide {
    position: relative;
}

.header-slide::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .3);
}

.header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    text-align: center;
}

.header-title h2 {
    text-shadow: 1px 1px 1px red;
}

.header-title h4 {
    text-shadow: 2px 2px 2px black;
}

.header-title .herobtn {
    position: relative;
    z-index: 99999;
}


@media only screen and (max-width: 900px) {
    .header-slide img {
        height: 400px;
    }
}

@media only screen and (max-width: 700px) {
    .header-slide img {
        height: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .header-slide img {
        height: 300px;
    }
}

@media only screen and (max-width: 500px) {
    .header-slide img {
        height: 300px;
    }
}

@media only screen and (max-width: 400px) {
    .header-slide img {
        height: 300px;
    }
}

@media only screen and (max-width: 320px) {
    .header-slide img {
        height: 250px;
    }
}

@media only screen and (max-width: 280px) {
    .header-slide img {
        height: 250px;
    }
}