.footer-item .footer-sub-menu li a {
    display: inline-block;
    color: #9CA3A4;
    transition: 0.5s ease-in-out;
    margin-top: 8px;
    font-weight: 500;
}

.footer-item .footer-sub-menu li a:hover {
    margin-left: 10px;
    color: orange;
}

